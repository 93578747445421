@import "../media.css";
@import "../mixins.css";
@import "../colors.css";

.component {
  &.small {
    /* default layout */
    .closeButton {
      top: 10px;
      right: 10px;
    }
  }

  /* LEFT MODAL */
  &.left {
    .scrollable {
      justify-content: flex-start;

      @media (--smartphone-and-tablet) {
        padding: 0;
      }
    }

    .modal {
      width: 260px;
      height: 100%;
      padding: 40px 0;
      @mixin background_primary;
    }

    .closeButton {
      font-size: 10px;
      top: 4px;
      right: 4px;
      height: 40px;
      width: 50px;
      @mixin background_primary;
    }
  }

  /* LEGAL MODAL */
  &.legal {
    .modal {
      margin: 20px;
      height: 100%;
      overflow-y: auto;
      width: 80%;

      @media (--desktop) {
        margin: 0px;
      }
    }

    .content {
      height: 100%;
    }

    .closeButton {
      position: fixed;
      font-size: 26px;
      top: 30px;
      right: 70px;

      @media (--tablet) {
        top: 90px;
        right: 11%;
      }

      @media (--desktop) {
        top: 90px;
        right: 13%;
      }
    }
  }

  &.right {
    .modal {
      @mixin background_secondary;
      position: fixed;
      right: 0px;
      top: 0px;
      bottom: 0px;
      z-index: 20;
      height: 100%;
      padding: 0;
      width: 345px;
      @media (max-width: 345px) {
        width: 320px;
      }
    }

    .content {
      height: 100%;
    }

    .scrollable {
      justify-content: flex-start;

      @media (--smartphone-and-tablet) {
        padding: 0;
      }
    }
  }

  &.roundedFlexible {
    .modal {
      border-radius: 4px;
      width: auto;
      @mixin background_primary;
      padding: 0;
      position: relative;
      .content {
        padding-bottom: 0;
      }
    }

    .scrollable {
      padding: 44px 0;
    }

    .closeButton {
      position: absolute;
      background: transparent;
      padding: 0;
      height: 20px;
      width: 20px;
      border: none;
      right: $spacingMd;
      top: $spacingMd;

      svg {
        height: 100%;
        width: 100%;
        path {
          stroke-width: 2px;
        }
      }
    }
  }

  &.roundedFlexibleFullscreen {
    .modal {
      width: 100%;
      @mixin background_primary;
      padding: 0;
      position: relative;
      height: 100%;

      @media (--tablet-and-desktop) {
        max-width: 800px;
        overflow-y: hidden;
      }
    }

    .scrollable {
      padding: 0;
    }

    .closeButton {
      position: absolute;
      background: transparent;
      padding: 0;
      height: 20px;
      width: 20px;
      border: none;
      right: $spacingMd;
      top: $spacingMd;

      svg {
        height: 100%;
        width: 100%;
        path {
          stroke-width: 2px;
        }
      }
    }

    @media (--tablet-and-desktop) {
      .modal {
        border-radius: 4px;
        width: auto;
        height: auto;
      }

      .scrollable {
        padding: 44px 0;
      }

      .closeButton {
        top: $spacingMd;
        right: $spacingMd;
      }
    }
  }

  &.roundedSmall {
    .modal {
      border-radius: 7px;
      width: calc(100% - 30px);
      max-width: 1080px;
      min-width: 300px;
      overflow: visible;
      @media (--tablet-and-desktop) {
        width: calc(100% - 104px);
      }
      @mixin background_secondary;
      padding: 0;
    }

    .scrollable {
      padding: 44px 0;
    }

    .closeButton {
      position: fixed;
      background: transparent;
      padding: 0;
      height: 20px;
      width: 20px;
      border: none;
      right: 20px;
      top: 10px;
      z-index: 150;
      @mixin icon_color_white;

      @media (--tablet-and-desktop) {
        top: 55px;
      }

      @media (min-width: 1184px) {
        right: calc(50% - 575px);
      }
    }
  }

  &.fullscreen {
    .scrollable {
      padding: 0;
    }

    .modal {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }

  &.fullscreenSelect {
    .scrollable {
      padding: 0;
    }

    .modal {
      width: 100%;
      height: 100%;
      padding: 0;
      @mixin background_primary;
    }

    .content {
      margin-top: $spacingHuge;
      margin-bottom: $spacingLg;
      height: 100%;
      overflow-y: scroll;

      @media (--smartphone) {
        margin-top: 50px;
        margin-bottom: 0;
      }
    }

  }

  &.fullscreenQuiz {
    .scrollable {
      padding: 0;
    }

    .modal {
      width: 100%;
      height: 100%;
      padding: 0;
    }

    .closeButton {
      background: white!important;
      -webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.1);
      @media (--desktop) {
        right: $spacingLg!important;
        top: $spacingMd!important;
      }
      @media (--smartphone-and-tablet) {
        right: $spacingSm!important;
        top: $spacingMd!important;
      }
    }
  }

  /* Modal Animations */
  /* These need to be in this file for reference to .modal */
  /* prefixed with layout name */

  /* LEFT MODAL ANIMATIONS */
  &.leftEnter {
    .modal {
      left: -260px;
    }
    .overlay {
      opacity: 0;
    }

    &.leftEnterActive {
      .modal {
        left: 0;
        transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
      }
      .overlay {
        opacity: 0.55;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
    }
  }

  &.leftLeave {
    .modal {
      left: 0;
      transition: left 200ms cubic-bezier(0.645, 0.045, 0.355, 1.000);
    }
    .overlay {
      opacity: 0.55;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;
    }

    &.leftLeaveActive {
      .modal {
        left: -260px;
      }
      .overlay {
        opacity: 0;
      }
    }
  }

  /* RIGHT MODAL ANIMATIONS */
  &.rightEnter {
    .modal {
      right: -345px;
      @media (max-width: 345px) {
        right: -320px;
      }
    }
    .overlay {
      opacity: 0;
    }

    &.rightEnterActive {
      .modal {
        right: 0px;
        transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      .overlay {
        opacity: 0.55;
        -webkit-transition: all .5s ease-in-out;
        transition: all .5s ease-in-out;
      }
    }
  }

  &.rightLeave {
    .modal {
      right: 0px;
      transition: right 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .overlay {
      opacity: 0.55;
      -webkit-transition: all .5s ease-in-out;
      transition: all .5s ease-in-out;
    }

    &.rightLeaveActive {
      .modal {
        right: -345px;
        @media (max-width: 345px) {
          right: -320px;
        }
      }
      .overlay {
        opacity: 0;
      }
    }
  }
}

/* ROUNDED SMALL MODAL ANIMATIONS */

.roundedSmallEnter {
  .modal {
    transform: translateY(20%);
    opacity: 0;
  }
  .closeButton {
    display: none;
  }
  .overlay {
    opacity: 0;
  }
  .gradient {
    opacity: 0;
  }
}

.roundedSmallEnterActive {
  .modal {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .closeButton {
    display: none;
  }
  .overlay {
    opacity: 0.55;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .gradient {
    opacity: 1;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.roundedSmallLeave {
  .modal {
    transform: translateY(0);
    opacity: 1;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .closeButton {
    display: none;
  }
  .overlay {
    opacity: 0.55;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .gradient {
    opacity: 1;
    transition: all 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

.roundedSmallLeaveActive {
  .modal {
    transform: translateY(20%);
    opacity: 0;
  }
  .closeButton {
    display: none;
  }
  .overlay {
    opacity: 0;
  }
  .gradient {
    opacity: 0;
  }
}

.gradient {
  @media (--smartphone) {
    content: "";
    display: block;
    background: linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0));
    width: 100%;
    height: 42px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 105;
  }
}

.overlay {
  position: fixed;
  background-color: $black;
  opacity: 0.55;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 103;
}

.scrollable {
  -webkit-overflow-scrolling: touch;
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 104;

  @media (--tablet-and-desktop) {
    padding: $spacingXl 0;
  }
}

.modal {
  align-items: center;
  @mixin background_secondary;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  min-height: 200px;
  overflow: auto;
  padding: $spacingMd $spacingMd $spacingLg $spacingMd;
  position: relative;
  width: 500px;

  &.transparent {
    background: transparent;
  }

  @media (--tablet-and-desktop) {
    padding: $spacingLg;
  }
}

.closeButton {
  background: $transparentGrey1;
  border-radius: 50%;
  @mixin text_primary;
  cursor: pointer;
  font-size: 20px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  z-index: 150;
  border: none;
  text-align: center;

  @media (--tablet-and-desktop) {
    right: $spacingNudge;
    top: $spacingXs;
  }
}

.content {
  @mixin text_primary;
  font-size: 14px;
  width: 100%;
}
