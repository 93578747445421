@import "../typography.css";
@import "../mixins.css";

.component {
  @mixin border_transparent;
  user-select: none;
  text-align: center;
  position: relative;
}

/* === Button Alignment === */
.block {
  display: block;
  width: 100%;
}

.inline {
  display: inline-block;
}

.rounded {
  border-radius: 100px;
}

/* This only works if the parent is flex or inline-flex */
.stretch {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 100%;
  height: 100%;
}

/* === Button Sizing === */
.xsmall {
  @mixin button_xsmall;
  padding: 0 $spacingSm;
}

.small {
  @mixin button_small;
  padding: 0 $spacingMd;
}

.medium {
  @mixin button_medium;
  padding: 0 $spacingMd;
}

.large {
  @mixin button_large;
  padding: 0 $spacingMd;
}

/* === Button Styling === */
.primary {
  @mixin button_primary;
}

.primary-outline {
  @mixin button_primary_outline;
}

.primary-transparent {
  @mixin button_primary_transparent;
}

.secondary {
  @mixin button_secondary;
}

.secondary-outline {
  @mixin button_secondary_outline;
}

.secondary-transparent {
  @mixin button_secondary_transparent;
}

.alternate {
  @mixin button_alternate;
}

.alternate-outline {
  @mixin button_alternate_outline;
}

.alternate-transparent {
  @mixin button_alternate_transparent;
}

.warning {
  @mixin button_warning;
}

.warning-link {
  @mixin button_warning_link;
}

.cancel-link {
  @mixin button_cancel_link;
}

.disabled-style {
  @mixin metaTitleText;
  @mixin background_primary__disabled;
  @mixin border_transparent;
  @mixin text_primary_alternate;
  text-transform: capitalize;

  &:disabled {
    @mixin background_primary__disabled;
    @mixin text_primary_alternate;
  }
}

.checkout {
  padding: 100px;
}

.plain-text {
  @mixin metaTitleText;
  @mixin text_primary;
  background: transparent;

  &:disabled {
    @mixin text_secondary_alternate;
  }
}

.link {
  &.component {
    @mixin linkText;
    border: none;
    padding: $spacingSm 0;
  }
}

.ctaButton {
  border: none;
  @mixin background_primary;
  padding: 0;
  appearance: none;
  @media (--smartphone-and-tablet) {
    margin-top: $spacingXs;
  }

  &.ctaAfter {
    @mixin captionDescriptionText;
    @mixin text_primary;
    @mixin text_link;
  }
}
